var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[(_vm.indexRentIncreaseInUse)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"small":"","color":"primary mb-1"},on:{"click":_vm.getLatestIndex}},[_vm._v("Hae viimeisin pisteluku")])],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[(_vm.indexRentIncreaseInUse)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"type":"number","step":"1","outlined":"","dense":"","label":"Alkuperäinen vuokra","suffix":"€","rules":_vm.validations.required,"hide-details":""},model:{value:(_vm.startRentPrice),callback:function ($$v) {_vm.startRentPrice=_vm._n($$v)},expression:"startRentPrice"}})],1):_vm._e(),(_vm.indexRentIncreaseInUse)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-menu',{ref:"indexMenu",attrs:{"close-on-content-click":false,"min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"},{name:"prevent-paste",rawName:"v-prevent-paste"}],attrs:{"value":_vm.dateFormattedNewIndexDate,"rules":_vm.validations.required,"outlined":"","dense":"","append-icon":"mdi-calendar","label":"Indeksin kk/vuosi","hide-details":"","loading":_vm.loadingIndex}},'v-text-field',attrs,false),on))]}}],null,false,2113898645),model:{value:(_vm.indexMenu),callback:function ($$v) {_vm.indexMenu=$$v},expression:"indexMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","type":"month","allowed-dates":_vm.allowedIndexDates},on:{"input":function($event){_vm.indexMenu = false},"change":_vm.getIndex},model:{value:(_vm.newIndexDate),callback:function ($$v) {_vm.newIndexDate=$$v},expression:"newIndexDate"}})],1)],1):_vm._e(),(_vm.indexRentIncreaseInUse)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"type":"number","step":"0.01","outlined":"","dense":"","label":"Uusi pisteluku","loading":_vm.loadingIndex,"rules":_vm.validations.required,"hide-details":""},model:{value:(_vm.newIndexValue),callback:function ($$v) {_vm.newIndexValue=_vm._n($$v)},expression:"newIndexValue"}})],1):_vm._e(),(
        _vm.currentContract.lease.rentIncreaseType === 'percentage' ||
        _vm.currentContract.lease.rentIncreaseType === 'indexPlusPercentage' ||
        _vm.currentContract.lease.rentIncreaseType === 'consumerIndexPlusPercentage' ||
        _vm.currentContract.lease.rentIncreaseType === 'propertyMaintenancePlusPercentageIndex'
      )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"type":"number","step":"0.01","rules":_vm.validations.zeroOrGreater,"outlined":"","dense":"","label":"Prosenttia","suffix":"%","hide-details":""},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=_vm._n($$v)},expression:"percentage"}})],1):_vm._e(),(_vm.currentContract.lease.rentIncreaseType === 'fixed')?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"type":"number","step":"0.01","rules":_vm.validations.required,"outlined":"","dense":"","label":"Euroa (€)","hide-details":""},model:{value:(_vm.fixedAmount),callback:function ($$v) {_vm.fixedAmount=_vm._n($$v)},expression:"fixedAmount"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }