<template>
  <div class="py-3 min-w-0 w-full">
    <v-card>
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Osoite, sopimusnumero tai päävuokralainen..."
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <!-- Colorcodes -->
        <div class="mt-1">
          <small>
            <v-icon class="mr-1" :color="'green'" small>mdi-clock-outline</v-icon>Aikaa tehdä
            korotus yli 1 kk
          </small>
          <br />
          <small>
            <v-icon class="mr-1" :color="'yellow'" small>mdi-clock-outline</v-icon>Aikaa tehdä
            korotus 15 päivää - 1 kk
          </small>
          <br />
          <small>
            <v-icon class="mr-1" :color="'red'" small>mdi-clock-outline</v-icon>Aikaa tehdä korotus
            alle 15 päivää
          </small>
        </div>
      </v-container>

      <v-data-table
        :headers="computedHeaders"
        :items="rentalContracts"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- CreatedBy -->
        <template #[`item.createdBy`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
            @click="switchToAccount(item.createdBy, '/rent-increase')"
            ><strong>{{ item.createdBy.name }} </strong></span
          >
        </template>

        <!--  Overview link to contract -->
        <template #[`item.contractNumber`]="{ item }">
          <router-link
            v-if="!isGroupMode()"
            style="text-decoration: none"
            :to="`/rental-contract/overview/${item._id}`"
          >
            <span class="font-weight-bold primary--text">
              {{ item.contractNumber }}
            </span>
          </router-link>

          <span v-else>
            {{ item.contractNumber }}
          </span>
        </template>

        <!--  Contract time -->
        <template #[`item.contractTime`]="{ item }">
          <span
            >{{ formatDate(item.lease.startDate) }} -
            <span v-if="item.lease.endDate">{{ formatDate(item.lease.endDate) }}</span></span
          >
        </template>

        <!--  Overview link to tenant -->
        <template #[`item.tenant.name`]="{ item }">
          <router-link
            v-if="!isGroupMode()"
            style="text-decoration: none"
            :to="`/tenant/overview/${item.tenant.tenantId._id}`"
          >
            <span class="primary--text">
              {{ item.tenant.name }}
            </span>
          </router-link>

          <span v-else>
            {{ item.tenant.name }}
          </span>
        </template>

        <!--  Apartment -->
        <template #[`item.apartment.address`]="{ item }">
          <router-link
            v-if="!isGroupMode()"
            style="text-decoration: none"
            :to="`/apartment/overview/${item.apartment.id._id}`"
          >
            <span>{{ item.apartment.id.address }}</span>
            <span v-if="item.apartment.id.apartmentNumber"
              >, {{ item.apartment.id.apartmentNumber }}
            </span>
          </router-link>

          <div v-else>
            <span>{{ item.apartment.id.address }}</span>
            <span v-if="item.apartment.id.apartmentNumber"
              >, {{ item.apartment.id.apartmentNumber }}
            </span>
          </div>
        </template>

        <!-- BaseInvoice -->
        <template #[`item.baseInvoice`]="{ item }">
          <div v-html="formatBaseInvoice(item.baseInvoice)"></div>
        </template>

        <!--  Next increase date -->
        <template #[`item.lease.nextRentIncreaseDate`]="{ item }">
          <span
            ><strong>{{ formatDate(item.lease.nextRentIncreaseDate) }}</strong></span
          >
        </template>

        <!--  Next current duedate -->
        <template #[`item.baseInvoice.nextDueDate`]="{ item }">
          <span>{{ formatDate(item.baseInvoice.nextDueDate) }}</span>
        </template>

        <!--  Time until rentIncrease -->
        <template #[`item.timeUntilRentIncrease`]="{ item }">
          <span
            ><v-icon
              class="mr-1"
              :color="`${getIncreaseColor(item.lease.nextRentIncreaseDate)}`"
              small
              >mdi-clock-outline</v-icon
            >{{ getTimeUntilNextIncrease(item.lease.nextRentIncreaseDate) }} pv
          </span>
        </template>

        <!--  Total amount -->
        <template #[`item.products`]="{ item }">
          <span class="font-weight-bold success--text">{{
            formatCurrency(getTotalAmount(item))
          }}</span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <!-- Groupmode -->
          <div v-if="isGroupMode()" class="action-grid-container"></div>

          <div v-else>
            <v-icon
              v-if="isAuthorized('rentIncrease', 'create')"
              title="Tee korotus"
              small
              @click="
                setCurrentContract(item);
                rentIncreaseDialog = true;
              "
              >mdi-send-clock-outline</v-icon
            >
          </div>
        </template>

        <template #no-data>
          <h2>Ei tulossa olevia korotuksia 3kk sisällä</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Dialog -->
    <rent-increase-dialog v-model="rentIncreaseDialog"></rent-increase-dialog>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import RentIncreaseDialog from "../../components/RentIncrease/RentIncreaseDialog";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import moment from "moment";
import globalValues from "@/configs/globalValues";

export default {
  components: {
    RentIncreaseDialog,
  },

  mixins: [mixins],

  data: () => ({
    loading: true,
    options: {},
    rentIncreaseDialog: false,
    search: "",
    headers: [
      {
        text: "Tili",
        value: "createdBy",
        show: [1],
      },
      { text: "Sopimusnro.", value: "contractNumber", show: [0, 1] },
      { text: "Sopimus", value: "contractTime", show: [0, 1], sortable: false },
      { text: "Kohde", value: "apartment.address", show: [0, 1] },
      { text: "Vuokralainen", value: "tenant.name", show: [0, 1] },
      {
        text: "Korotuspvm.",
        value: "lease.nextRentIncreaseDate",
        show: [0, 1],
      },
      {
        text: "Aikaa korotukseen (-1 kk)",
        value: "timeUntilRentIncrease",
        show: [0, 1],
        sortable: false,
      },
      { text: "Toistuvaislasku", value: "baseInvoice", show: [0, 1], sortable: false },
      {
        text: "Seuraavan eräpvm.",
        value: "baseInvoice.nextDueDate",
        show: [0, 1],
        sortable: false,
      },
      { text: "Saatavat", value: "products", show: [0, 1], sortable: false },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [0],
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("rentIncrease", ["rentalContracts", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    computedHeaders() {
      return this.headers.filter((el) => {
        if (this.isGroupMode()) {
          if (el.show.includes(1)) return el;
        } else {
          if (el.show.includes(0)) return el;
        }
      });
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("account", ["switchAccount"]),
    ...mapActions("rentIncrease", ["getRentalContracts"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("rentIncrease", ["setCurrentContract"]),
    ...mapGetters("account", ["getSelectedAccounts"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/rent-increase/get-rental-contracts?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getRentalContracts({ url, data: { accountSelector: this.getSelectedAccounts() } });

      this.loading = false;
    },

    getTotalAmount(item) {
      const products = item.baseInvoice.isUpdated
        ? item.baseInvoice.updatedProducts
        : item.baseInvoice.products;

      return products.reduce((sum, cur) => sum + cur.amount * cur.count * (cur.taxpr / 100 + 1), 0);
    },

    formatBaseInvoice(invoice) {
      if (invoice && invoice.invoiceNumber) {
        return `<span class="font-weight-bold success--text">${invoice.invoiceNumber}</span>`;
      } else if (invoice && !invoice.invoiceNumber) {
        return `<span class="font-weight-bold error--text">Luotu, ei lähetetty</span>`;
      } else {
        return "";
      }
    },

    getTimeUntilNextIncrease(date) {
      const increaseDate = moment(date).format("YYYY-MM-DD");
      const monthBeforeIncreaseDate = moment(increaseDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      return moment(monthBeforeIncreaseDate).diff(moment().format("YYYY-MM-DD"), "days");
    },

    getIncreaseColor(date) {
      const increaseDate = moment(date).format("YYYY-MM-DD");
      const monthBeforeIncreaseDate = moment(increaseDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      const nextMonth = moment().add(1, "months").format("YYYY-MM-DD");
      const in15Days = moment().add(15, "days").format("YYYY-MM-DD");

      if (moment(monthBeforeIncreaseDate).isAfter(nextMonth)) {
        return "green";
      } else if (moment(monthBeforeIncreaseDate).isSameOrAfter(in15Days)) {
        return "yellow";
      } else {
        return "red";
      }
    },
  },
};
</script>

<style scoped></style>
