<template>
  <div class="price-wrapper">
    <h3 class="mb-1">{{ title }}</h3>

    <div class="mt-2" v-if="products.length > 0">
      <div class="mb-1">
        <p class="mb-1">
          <strong>Tuotteet</strong>
        </p>

        <div v-for="(product, idx) in products" :key="`${idx}` + 'a'" class="product-wrapper">
          <div>
            {{
              replaceProductTags(
                product.desc,
                computedNextDueDate,
                getNextAccountDate(currentContract.baseInvoice, computedNextDueDate),
                currentContract.baseInvoice.language
              )
            }}
          </div>
          <div
            :class="{
              overwritten:
                productType === 'currentProducts' &&
                product.productId &&
                product.productId.productType === 'Vuokra',
            }"
          >
            {{ formatCurrency(product.amount * product.count) }}
          </div>
        </div>
      </div>

      <p class="product-wrapper">
        <strong>Yhteensä ilman alv:</strong>
        <strong
          :class="{
            overwritten: productType === 'currentProducts',
          }"
          >{{ formatCurrency(totalSum()) }}</strong
        >
      </p>

      <p v-if="totalVat()['vat10'] != 0" class="product-wrapper">
        <strong>Arvonlisävero 10 %:</strong>
        <strong
          :class="{
            overwritten: productType === 'currentProducts' && rentProductIncludesVat.vat10,
          }"
          >{{ formatCurrency(totalVat()["vat10"]) }}</strong
        >
      </p>

      <p v-if="totalVat()['vat14'] != 0" class="product-wrapper">
        <strong>Arvonlisävero 14 %:</strong>
        <strong
          :class="{
            overwritten: productType === 'currentProducts' && rentProductIncludesVat.vat14,
          }"
          >{{ formatCurrency(totalVat()["vat14"]) }}</strong
        >
      </p>

      <p v-if="totalVat()['vat24'] != 0" class="product-wrapper">
        <strong>Arvonlisävero 24 %:</strong>
        <strong
          :class="{
            overwritten: productType === 'currentProducts' && rentProductIncludesVat.vat24,
          }"
          >{{ formatCurrency(totalVat()["vat24"]) }}</strong
        >
      </p>

      <p v-if="totalVat()['vat255'] != 0" class="product-wrapper">
        <strong>Arvonlisävero 25,5 %:</strong>
        <strong
          :class="{
            overwritten: productType === 'currentProducts' && rentProductIncludesVat.vat255,
          }"
          >{{ formatCurrency(totalVat()["vat255"]) }}</strong
        >
      </p>

      <p class="mt-2 success--text product-wrapper">
        <strong>Yhteensä:</strong>
        <strong
          :class="{
            overwritten: productType === 'currentProducts',
          }"
          >{{ formatCurrency(totalSumWithVat()) }}</strong
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import helpers from "@/utils/rentIncreaseHelpers";
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import invoiceHelpers from "../../utils/invoiceHelpers";
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    productType: {
      type: String,
      default: "currentProducts",
    },
  },

  mixins: [mixins, invoiceMixins],

  computed: {
    ...mapState("rentIncrease", ["currentContract", "newRentIncrease"]),
    ...mapGetters("rentIncrease", ["currentProducts", "factors"]),

    title() {
      return this.productType === "currentProducts"
        ? "Nykyinen toistuvaislasku"
        : "Korotettu toistuvaislasku";
    },

    products() {
      let products = [];

      switch (this.productType) {
        case "currentProducts":
          products = this.currentProducts;
          break;
        case "formattedProducts":
          products = this.formattedProducts;
          break;
        case "rentIncreaseProducts":
          products = this.newRentIncrease.products;
      }

      return products;
    },

    computedNextDueDate() {
      let date;

      if (this.productType == "currentProducts") {
        date = this.currentContract.baseInvoice.nextDueDate;
      }

      if (this.productType == "formattedProducts") {
        const nextRentIncreaseDate = new Date(this.currentContract.lease.nextRentIncreaseDate);
        const year = nextRentIncreaseDate.getFullYear();
        const month = nextRentIncreaseDate.getMonth();

        const otherDueDates =
          this.currentContract.baseInvoice.otherDueDates != 100
            ? this.currentContract.baseInvoice.otherDueDates
            : this.calcLastDayOfMonthDate(year, month);

        date = new Date(moment([year, month, otherDueDates]).format("YYYY-MM-DD"));
      }

      if (this.productType == "rentIncreaseProducts") {
        date = this.newRentIncrease.dueDate;
      }

      return date;
    },

    formattedProducts() {
      const products = _.cloneDeep(this.currentProducts);
      let newProducts = [];

      products.forEach((el) => {
        if (el.productId && el.productId.productType === "Vuokra") {
          el.amount = helpers.countNewSum(
            el,
            this.currentContract,
            this.newRentIncrease,
            this.factors
          );
          newProducts.push(el);
        } else {
          newProducts.push(el);
        }
      });

      return newProducts;
    },

    rentProductIncludesVat() {
      let vat10 = false;
      let vat14 = false;
      let vat24 = false;
      let vat255 = false;

      this.products.forEach((el) => {
        if (el.productId && el.productId.productType === "Vuokra") {
          if (el.taxpr === 10) vat10 = true;
          if (el.taxpr === 14) vat14 = true;
          if (el.taxpr === 24) vat24 = true;
          if (el.taxpr === 25.5) vat255 = true;
        }
      });

      return { vat10, vat14, vat24, vat255 };
    },
  },

  methods: {
    totalSum() {
      let sum = 0;

      this.products.forEach((el) => {
        sum += el.amount * el.count;
      });

      return sum;
    },

    totalSumWithVat() {
      let sum = 0;

      this.products.forEach((el) => {
        sum += el.amount * el.count * (1 + el.taxpr / 100);
      });

      return sum;
    },

    totalVat() {
      const { vat10, vat14, vat24, vat255 } = invoiceHelpers.calcTotalVats(this.products);
      return { vat10, vat14, vat24, vat255 };
    },
  },
};
</script>

<style scoped>
.product-wrapper {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.overwritten {
  color: #6d6d6daa;
  text-decoration: line-through;
}

.price-wrapper {
  max-width: 100%;
  margin: auto;
}
</style>
