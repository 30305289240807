<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 mb-2">Vuokrankorotukset</div>

    <v-container fluid style="padding: 0px; margin: 0px">
      <!-- TABS -->
      <v-tabs
        v-model="tab"
        flat
        color="primary"
        style="margin: 0px; padding: 0px"
        fixed-tabs
        show-arrows
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="(item, index) in computedTabs" :key="'a' + index">
          <span>{{ item }} </span>
        </v-tab>
      </v-tabs>
    </v-container>

    <!-- Outgoing payments table -->
    <coming-rent-increase-table v-if="tab === 0"></coming-rent-increase-table>
    <!-- Other payments table -->
    <done-rent-increase-table v-if="tab === 1 && !isGroupMode()"></done-rent-increase-table>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import ComingRentIncreaseTable from "../../components/RentIncrease/ComingRentIncreaseTable.vue";
import DoneRentIncreaseTable from "../../components/RentIncrease/DoneRentIncreaseTable.vue";

export default {
  title: "Vuokrankorotukset",

  mixins: [mixins],

  components: {
    ComingRentIncreaseTable,
    DoneRentIncreaseTable,
  },

  data: () => ({
    tab: 0,
    tabs: ["Korottamattomat", "Korotetut"],
  }),

  computed: {
    computedTabs() {
      return this.tabs.filter((tab) => {
        if (this.isGroupMode()) {
          if (tab !== "Korotetut") return tab;
        } else {
          return tab;
        }
      });
    },
  },
};
</script>

<style scoped></style>
