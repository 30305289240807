<template>
  <v-form ref="form">
    <v-row v-if="indexRentIncreaseInUse" dense>
      <v-col>
        <v-btn small color="primary mb-1" @click="getLatestIndex">Hae viimeisin pisteluku</v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col v-if="indexRentIncreaseInUse" cols="12" sm="6" md="4" lg="3">
        <v-text-field
          v-model.number="startRentPrice"
          type="number"
          step="1"
          outlined
          dense
          label="Alkuperäinen vuokra"
          suffix="€"
          :rules="validations.required"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col v-if="indexRentIncreaseInUse" cols="12" sm="6" md="4" lg="3">
        <v-menu ref="indexMenu" v-model="indexMenu" :close-on-content-click="false" min-width="290">
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="dateFormattedNewIndexDate"
              v-bind="attrs"
              :rules="validations.required"
              v-on="on"
              outlined
              dense
              append-icon="mdi-calendar"
              v-prevent-manual-input
              v-prevent-paste
              label="Indeksin kk/vuosi"
              hide-details
              :loading="loadingIndex"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="newIndexDate"
            first-day-of-week="1"
            type="month"
            @input="indexMenu = false"
            @change="getIndex"
            :allowed-dates="allowedIndexDates"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="indexRentIncreaseInUse" cols="12" sm="6" md="4" lg="3">
        <v-text-field
          v-model.number="newIndexValue"
          type="number"
          step="0.01"
          outlined
          dense
          label="Uusi pisteluku"
          :loading="loadingIndex"
          :rules="validations.required"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col
        v-if="
          currentContract.lease.rentIncreaseType === 'percentage' ||
          currentContract.lease.rentIncreaseType === 'indexPlusPercentage' ||
          currentContract.lease.rentIncreaseType === 'consumerIndexPlusPercentage' ||
          currentContract.lease.rentIncreaseType === 'propertyMaintenancePlusPercentageIndex'
        "
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-model.number="percentage"
          type="number"
          step="0.01"
          :rules="validations.zeroOrGreater"
          outlined
          dense
          label="Prosenttia"
          suffix="%"
          hide-details
        ></v-text-field
      ></v-col>

      <v-col
        v-if="currentContract.lease.rentIncreaseType === 'fixed'"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-model.number="fixedAmount"
          type="number"
          step="0.01"
          :rules="validations.required"
          outlined
          dense
          label="Euroa (€)"
          hide-details
        ></v-text-field
      ></v-col>
    </v-row>
  </v-form>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState, mapMutations, mapActions } from "vuex";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  data() {
    return {
      validations,
      indexMenu: false,
    };
  },

  computed: {
    ...mapState("rentIncrease", [
      "currentContract",
      "newRentIncrease",
      "indexValue",
      "indexDate",
      "loadingIndex",
    ]),

    indexRentIncreaseInUse() {
      const type = this.currentContract?.lease?.rentIncreaseType;
      if (
        type === "index" ||
        type === "indexPlusPercentage" ||
        type === "consumerIndex" ||
        type === "consumerIndexPlusPercentage" ||
        type === "propertyMaintenanceIndex" ||
        type === "propertyMaintenancePlusPercentageIndex"
      ) {
        return true;
      } else {
        return false;
      }
    },

    newIndexDate: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.newIndexDate;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "newIndexDate" });
      },
    },
    newIndexValue: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.newIndexValue;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "newIndexValue" });
      },
    },
    percentage: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.percentage;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "percentage" });
      },
    },
    startRentPrice: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.startRentPrice;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "startRentPrice" });
      },
    },
    fixedAmount: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.fixedAmount;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "fixedAmount" });
      },
    },

    dateFormattedNewIndexDate() {
      return this.formatDateMonthName(this.$store.state.rentIncrease.newRentIncrease.newIndexDate);
    },
  },

  watch: {
    indexValue(val) {
      this.setNewRentIncreaseValue({ val, field: "newIndexValue" });
    },

    indexDate(val) {
      this.setNewRentIncreaseValue({ val, field: "newIndexDate" });
    },

    newIndexValue() {
      this.setButtonAnimation(true);
    },
    percentage() {
      this.setButtonAnimation(true);
    },
    fixedAmount() {
      this.setButtonAnimation(true);
    },
    startRentPrice() {
      this.setButtonAnimation(true);
    },
  },

  methods: {
    ...mapActions("rentIncrease", ["getIndexValue", "getLatestIndexValue"]),
    ...mapMutations("rentIncrease", [
      "setNewRentIncreaseValue",
      "setLoadingIndex",
      "setButtonAnimation",
    ]),

    getIndex(date) {
      if (date) {
        this.setLoadingIndex(true);
        this.getIndexValue({ date, type: this.currentContract.lease.rentIncreaseType });
      }
    },

    getLatestIndex() {
      this.setNewRentIncreaseValue({ val: null, field: "newIndexValue" });
      this.setNewRentIncreaseValue({ val: "", field: "newIndexDate" });
      this.setLoadingIndex(true);
      this.getLatestIndexValue(this.currentContract.lease.rentIncreaseType);
    },

    allowedIndexDates(val) {
      const indexType = this.currentContract.lease.rentIncreaseType;
      const dateWithoutYear = moment(val).format("MM");

      const allowedQMonths = ["01", "04", "07", "10"];

      if (
        indexType === "propertyMaintenanceIndex" ||
        indexType === "propertyMaintenancePlusPercentageIndex"
      ) {
        if (allowedQMonths.includes(dateWithoutYear)) return true;
        else return false;
      } else {
        return true;
      }
    },

    resetForm() {
      // Reset fields when closing the whole dialog
      this.$refs.form.reset();
      this.setNewRentIncreaseValue({
        val: null,
        field: "newIndexDate",
      });
    },

    validateForm() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped></style>
