var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"price-wrapper"},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.title))]),(_vm.products.length > 0)?_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"mb-1"},[_vm._m(0),_vm._l((_vm.products),function(product,idx){return _c('div',{key:"" + idx + 'a',staticClass:"product-wrapper"},[_c('div',[_vm._v(" "+_vm._s(_vm.replaceProductTags( product.desc, _vm.computedNextDueDate, _vm.getNextAccountDate(_vm.currentContract.baseInvoice, _vm.computedNextDueDate), _vm.currentContract.baseInvoice.language ))+" ")]),_c('div',{class:{
            overwritten:
              _vm.productType === 'currentProducts' &&
              product.productId &&
              product.productId.productType === 'Vuokra',
          }},[_vm._v(" "+_vm._s(_vm.formatCurrency(product.amount * product.count))+" ")])])})],2),_c('p',{staticClass:"product-wrapper"},[_c('strong',[_vm._v("Yhteensä ilman alv:")]),_c('strong',{class:{
          overwritten: _vm.productType === 'currentProducts',
        }},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalSum())))])]),(_vm.totalVat()['vat10'] != 0)?_c('p',{staticClass:"product-wrapper"},[_c('strong',[_vm._v("Arvonlisävero 10 %:")]),_c('strong',{class:{
          overwritten: _vm.productType === 'currentProducts' && _vm.rentProductIncludesVat.vat10,
        }},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalVat()["vat10"])))])]):_vm._e(),(_vm.totalVat()['vat14'] != 0)?_c('p',{staticClass:"product-wrapper"},[_c('strong',[_vm._v("Arvonlisävero 14 %:")]),_c('strong',{class:{
          overwritten: _vm.productType === 'currentProducts' && _vm.rentProductIncludesVat.vat14,
        }},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalVat()["vat14"])))])]):_vm._e(),(_vm.totalVat()['vat24'] != 0)?_c('p',{staticClass:"product-wrapper"},[_c('strong',[_vm._v("Arvonlisävero 24 %:")]),_c('strong',{class:{
          overwritten: _vm.productType === 'currentProducts' && _vm.rentProductIncludesVat.vat24,
        }},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalVat()["vat24"])))])]):_vm._e(),(_vm.totalVat()['vat255'] != 0)?_c('p',{staticClass:"product-wrapper"},[_c('strong',[_vm._v("Arvonlisävero 25,5 %:")]),_c('strong',{class:{
          overwritten: _vm.productType === 'currentProducts' && _vm.rentProductIncludesVat.vat255,
        }},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalVat()["vat255"])))])]):_vm._e(),_c('p',{staticClass:"mt-2 success--text product-wrapper"},[_c('strong',[_vm._v("Yhteensä:")]),_c('strong',{class:{
          overwritten: _vm.productType === 'currentProducts',
        }},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalSumWithVat())))])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Tuotteet")])])}]

export { render, staticRenderFns }