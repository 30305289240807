var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('v-card',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Osoite, sopimusnumero tai päävuokralainen...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('div',{staticClass:"mt-1"},[_c('small',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":'green',"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v("Aikaa tehdä korotus yli 1 kk ")],1),_c('br'),_c('small',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":'yellow',"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v("Aikaa tehdä korotus 15 päivää - 1 kk ")],1),_c('br'),_c('small',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":'red',"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v("Aikaa tehdä korotus alle 15 päivää ")],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.rentalContracts,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.switchToAccount(item.createdBy, '/rent-increase')}}},[_c('strong',[_vm._v(_vm._s(item.createdBy.name)+" ")])])]}},{key:"item.contractNumber",fn:function(ref){
var item = ref.item;
return [(!_vm.isGroupMode())?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (item._id))}},[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(" "+_vm._s(item.contractNumber)+" ")])]):_c('span',[_vm._v(" "+_vm._s(item.contractNumber)+" ")])]}},{key:"item.contractTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.lease.startDate))+" - "),(item.lease.endDate)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.lease.endDate)))]):_vm._e()])]}},{key:"item.tenant.name",fn:function(ref){
var item = ref.item;
return [(!_vm.isGroupMode())?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (item.tenant.tenantId._id))}},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.tenant.name)+" ")])]):_c('span',[_vm._v(" "+_vm._s(item.tenant.name)+" ")])]}},{key:"item.apartment.address",fn:function(ref){
var item = ref.item;
return [(!_vm.isGroupMode())?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/apartment/overview/" + (item.apartment.id._id))}},[_c('span',[_vm._v(_vm._s(item.apartment.id.address))]),(item.apartment.id.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.apartment.id.apartmentNumber)+" ")]):_vm._e()]):_c('div',[_c('span',[_vm._v(_vm._s(item.apartment.id.address))]),(item.apartment.id.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.apartment.id.apartmentNumber)+" ")]):_vm._e()])]}},{key:"item.baseInvoice",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatBaseInvoice(item.baseInvoice))}})]}},{key:"item.lease.nextRentIncreaseDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('strong',[_vm._v(_vm._s(_vm.formatDate(item.lease.nextRentIncreaseDate)))])])]}},{key:"item.baseInvoice.nextDueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.baseInvoice.nextDueDate)))])]}},{key:"item.timeUntilRentIncrease",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":("" + (_vm.getIncreaseColor(item.lease.nextRentIncreaseDate))),"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v(_vm._s(_vm.getTimeUntilNextIncrease(item.lease.nextRentIncreaseDate))+" pv ")],1)]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.getTotalAmount(item))))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isGroupMode())?_c('div',{staticClass:"action-grid-container"}):_c('div',[(_vm.isAuthorized('rentIncrease', 'create'))?_c('v-icon',{attrs:{"title":"Tee korotus","small":""},on:{"click":function($event){_vm.setCurrentContract(item);
              _vm.rentIncreaseDialog = true;}}},[_vm._v("mdi-send-clock-outline")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei tulossa olevia korotuksia 3kk sisällä")])]},proxy:true}],null,true)})],1),_c('rent-increase-dialog',{model:{value:(_vm.rentIncreaseDialog),callback:function ($$v) {_vm.rentIncreaseDialog=$$v},expression:"rentIncreaseDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }