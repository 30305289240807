<template>
  <div class="py-3 min-w-0 w-full">
    <v-card>
      <v-container>
        <!-- <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae kaikista kentistä..."
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row> -->

        <v-container fluid>
          <!-- TABS -->
          <v-tabs
            v-model="tab"
            flat
            color="primary"
            style="margin: 0px; padding: 0px"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="'a' + index">
              <span>{{ item }} </span>
            </v-tab>
          </v-tabs>
        </v-container>
      </v-container>

      <v-data-table
        :headers="computedHeaders"
        :items="rentIncreases"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <template #top>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <small v-if="tab == 0"
                  >Odottavilla korotuksilla laskutus ei ole seuraavan toistuvan laskun
                  yhteydessä.</small
                >
                <small v-if="tab == 1"
                  >Aktivoiduilla korotuksilla korotettu lasku lähetetään seuraavan laskun yhteydessä
                  ja korotetut tuotteet on siirretty laskulle.</small
                >
                <small v-if="tab == 2"
                  >Laskutetuilla korotuksilla ensimmäinen korotettu lasku on jo lähetetty.</small
                >
              </v-col>
            </v-row>
          </v-container>
        </template>

        <!--  Overview link -->
        <template #[`item.rentalContract.contractNumber`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/rental-contract/overview/${item.rentalContract._id}`"
          >
            <span class="font-weight-bold primary--text">
              {{ item.rentalContract.contractNumber }}
            </span>
          </router-link>
        </template>

        <!--  Apartment -->
        <template #[`item.apartment`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.rentalContract.apartment.id._id}`"
          >
            <span>{{ item.rentalContract.apartment.id.address }}</span>
            <span v-if="item.rentalContract.apartment.id.apartmentNumber"
              >, {{ item.rentalContract.apartment.id.apartmentNumber }}
            </span>
          </router-link>
        </template>

        <!--  Overview link to tenant -->
        <template #[`item.tenant`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/tenant/overview/${item.rentalContract.tenant.tenantId._id}`"
          >
            <span class="primary--text">
              {{ item.rentalContract.tenant.name }}
            </span>
          </router-link>
        </template>

        <!-- Duedate -->
        <template #[`item.dueDate`]="{ item }">
          <span>{{ formatDate(item.dueDate) }}</span>
        </template>

        <!-- BaseInvoice -->
        <template #[`item.baseInvoice`]="{ item }">
          <div v-html="formatBaseInvoice(item.baseInvoice)"></div>
        </template>

        <!-- Products / show only on drafts!  -->
        <template #[`item.productDrafts`]="{ item }">
          <div v-html="formatProductDrafts(item)"></div>
        </template>

        <!--  Send date -->
        <template #[`item.sendDate`]="{ item }">
          <span>{{ formatDate(formatSendDate(item)) }}</span>
        </template>

        <!-- Message to customer -->
        <template #[`item.dontSendMessage`]="{ item }">
          <p>
            <span v-if="item.dontSendMessage">{{ formatBoolean(!item.dontSendMessage) }}</span>
            <span v-if="!item.dontSendMessage">{{ formatDate(item.createdAt) }}</span>
          </p>
          <div
            v-if="
              formatRentIncreaseMessageStatus(item.rentIncreaseMessageStatus) &&
              !item.dontSendMessage
            "
          >
            <span
              class="font-weight-bold"
              :class="getRentIncreaseMessageColor(item.rentIncreaseMessageStatus)"
              >{{ formatRentIncreaseMessageStatus(item.rentIncreaseMessageStatus) }}</span
            >
          </div>
        </template>

        <!--  Send type -->
        <template #[`item.sendType`]="{ item }">
          <span
            ><v-icon small>{{ getSendIcon(item.sendType) }}</v-icon></span
          >
        </template>

        <!--  Total amount -->
        <template #[`item.products`]="{ item }">
          <span class="font-weight-bold success--text">{{
            formatCurrency(getTotalAmount(item.products))
          }}</span>
        </template>

        <!-- Active -->
        <template #[`item.activated`]="{ item }">
          <span
            class="font-weight-bold"
            :class="
              !item.activated
                ? 'draft--text'
                : item.activated && !item.done
                ? 'warning--text'
                : 'success--text'
            "
            >{{
              !item.activated
                ? "Odottaa aktivointia"
                : item.activated && !item.done
                ? "Aktivoitu / Ei laskutettu"
                : "Valmis / Laskutettu"
            }}</span
          >
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('rentIncrease', 'read')"
              title="Lataa pdf"
              small
              @click="downloadPdf(item)"
              >mdi-file-pdf</v-icon
            >
            <v-icon
              v-if="isAuthorized('rentIncrease', 'delete') && !item.done"
              title="Poista korotus"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>
            {{
              tab == 0
                ? "Ei aktivointia odottavia korotuksia"
                : tab == 1
                ? "Ei aktivoituja korotuksia"
                : "Ei lähetettyjä korotuksia"
            }}
          </h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Dialog -->
    <rent-increase-dialog v-model="rentIncreaseDialog"></rent-increase-dialog>

    <!-- Delete active rentincrease -->
    <delete-active-rent-increase v-model="deleteRentIncreaseDialog"></delete-active-rent-increase>

    <!-- Download loader -->
    <download-loader
      v-model="downloading"
      :loading="downloading"
      text="Odota, ladataan vuokrankorotusilmoitusta..."
    ></download-loader>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import RentIncreaseDialog from "../../components/RentIncrease/RentIncreaseDialog";
import DeleteActiveRentIncrease from "./DeleteActiveRentIncrease.vue";
import DownloadLoader from "../../components/DownloadLoader.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import { apiAgent } from "../../services/apiAgent";
import axiosMethods from "../../mixins/axios";
import globalValues from "@/configs/globalValues";

export default {
  components: {
    RentIncreaseDialog,
    DownloadLoader,
    DeleteActiveRentIncrease,
  },

  mixins: [mixins, invoiceMixins],

  data: () => ({
    tab: 0,
    tabs: ["Odottaa", "Aktivoitu", "Laskutettu"],
    downloading: false,
    loading: true,
    options: {},
    rentIncreaseDialog: false,
    search: "",
    headers: [
      {
        text: "Sopimusnro.",
        value: "rentalContract.contractNumber",
        show: [1, 2],
        sortable: false,
      },
      { text: "Kohde", value: "apartment", show: [1, 2], sortable: false },
      { text: "Vuokralainen", value: "tenant", show: [1, 2], sortable: false },
      { text: "Lasku", value: "baseInvoice", show: [1, 2], sortable: false },
      { text: "Tuotteet", value: "productDrafts", show: [1, 2], sortable: false },
      { text: "Saatavat", value: "products", show: [1, 2], sortable: false },
      { text: "Eräpvm.", value: "dueDate", show: [1, 2] },
      { text: "Lähetyspvm.", value: "sendDate", show: [1] },
      { text: "Korotusilm.", value: "dontSendMessage", show: [1, 2] },
      { text: "1. laskun toimitus", value: "sendType", show: [1] },
      { text: "Tila", value: "activated", show: [1, 2], sortable: false },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [1, 2],
      },
    ],
    deleteRentIncreaseDialog: false,
    globalValues,
  }),

  computed: {
    ...mapState("rentIncrease", ["rentIncreases", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    computedHeaders() {
      if (this.tab == 0) {
        return this.headers.filter((el) => el.show.includes(1));
      } else {
        return this.headers.filter((el) => el.show.includes(2));
      }
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },

    tab() {
      this.getDataFromApi();
    },
  },

  methods: {
    ...mapActions("rentIncrease", ["getRentIncreases", "deleteInActiveRentIncrease"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("rentIncrease", ["setCurrentRentIncrease"]),
    ...mapMutations("pdfViewer", ["openViewer"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      let url = `/api/v1/rent-increase/get-rent-increases?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}&showType=${this.tab}`;

      await this.getRentIncreases(url);

      this.loading = false;
    },

    getTotalAmount(products) {
      return products.reduce((sum, cur) => sum + cur.amount * cur.count * (cur.taxpr / 100 + 1), 0);
    },

    formatBaseInvoice(invoice) {
      if (invoice && invoice.invoiceNumber) {
        return `<span class="font-weight-bold success--text">${invoice.invoiceNumber}</span>`;
      } else if (invoice && !invoice.invoiceNumber) {
        return `<span class="font-weight-bold error--text">Luotu, ei lähetetty</span>`;
      } else {
        return "";
      }
    },

    formatProductDrafts(item) {
      let productsString = "";

      item.products.forEach((el) => {
        productsString += `<div>${this.replaceProductTags(
          el.desc,
          item.dueDate,
          this.getNextAccountDate(item.baseInvoice, item.dueDate),
          item.baseInvoice.language
        )} | ${this.formatCurrency(el.count * el.amount * (1 + el.taxpr / 100))}</div>`;
      });

      return productsString;
    },

    formatSendDate(item) {
      const dueDate = moment(item.dueDate);

      const billDate = dueDate.subtract(item.paymentTerm, "days").format("YYYY-MM-DD");

      if (Date.now() > new Date(billDate).getTime()) {
        return moment(Date.now() + 24 * 60 * 60 * 1000).format("YYYY-MM-DD");
      } else return billDate;
    },

    getTimeUntilNextIncrease(increaseDate) {
      return moment(increaseDate).diff(moment(new Date()).format("YYYY-MM-DD"), "days");
    },

    async downloadPdf(item) {
      try {
        this.downloading = true;

        const res = await apiAgent({
          method: "get",
          url: `/api/v1/rent-increase/download-pdf/${item._id}`,
          responseType: "arraybuffer",
        });

        const blob = new Blob([res.data], { type: "application/pdf" });

        this.downloading = false;

        this.openViewer({
          data: blob,
          name: `Vuokrankorotus_sop.num._${item.rentalContract.contractNumber}.pdf`,
        });
      } catch (err) {
        this.downloading = false;
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    deleteItem(item) {
      if (item.activated) {
        // Delete active rentincrease
        this.setCurrentRentIncrease(item);
        this.deleteRentIncreaseDialog = true;
      } else {
        if (
          confirm(
            `Haluatko varmasti poistaa odottavan vuokrankorotuksen sopimukselta ${item.rentalContract.contractNumber}?`
          )
        ) {
          // Delete inactive rentincrease
          this.deleteInActiveRentIncrease(item._id);
        }
      }
    },
  },
};
</script>

<style scoped></style>
