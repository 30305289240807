<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title
        >Poista aktivoitu vuokrankorotus laskulta
        {{ currentRentIncrease.baseInvoice.invoiceNumber }}</v-card-title
      >
      <v-card-text>
        <h4 class="mb-1">Ennen vuokrankorotusta laskulla olleet tuotteet:</h4>

        <div
          v-for="(prod, i) in currentRentIncrease.previousProducts"
          :key="i"
          class="product-wrapper"
        >
          <div>{{ prod.desc }}</div>
          <div>{{ formatCurrency(prod.amount * prod.count) }}</div>
        </div>

        <h4 class="mt-1 mb-1">Haluatko palauttaa tuotteet poiston yhteydessä laskulle?</h4>
        <v-checkbox
          style="margin-top: 0px"
          v-model="returnPreviousProducts"
          label="Palauta tuotteet"
          hide-details
        ></v-checkbox>

        <p class="mt-1" :class="returnPreviousProducts ? 'warning--text' : 'success--text'">
          {{
            returnPreviousProducts
              ? "Ennen korotusta olleet tuotteet palautetaan laskulle."
              : "Korotetut tuotteet jätetään laskulle."
          }}
        </p>
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="deleteRentIncrease">Poista korotus</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import mixins from "../../mixins/mixins";
export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      returnPreviousProducts: false,
    };
  },

  computed: {
    ...mapState("rentIncrease", ["currentRentIncrease"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("rentIncrease", ["deleteActiveRentIncrease"]),

    async deleteRentIncrease() {
      try {
        await this.deleteActiveRentIncrease({
          id: this.currentRentIncrease._id,
          returnPreviousProducts: this.returnPreviousProducts,
        });
        this.dialog = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.product-wrapper {
  max-width: 300px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>
